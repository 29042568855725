import React from "react";

// reactstrap components
import DemoNavbar from "../../components/Navbars/DemoNavbar.js";
import SimpleFooter from "../../components/Footers/SimpleFooter.js";
import { Card, Container, Row, Col} from "reactstrap";
import { motion } from "framer-motion"

class KnowMore extends React.Component {
  componentDidMount() {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    this.refs.main.scrollTop = 0;
  }

  handleClickMore(entry){
    setTimeout(()=>{
      let allElements = document.getElementsByClassName('d-none')
      let elementsToShow = []
      for(let i = 0; i < allElements.length; i++){
        let element = allElements[i]
        if(element.dataset.entry == entry){
          elementsToShow.push(element)
        }
      }    
      for(let i = 0; i < elementsToShow.length; i++){
        let element = elementsToShow[i]
        element.classList.remove("d-none")
      } 
      window.scrollTo({ top: document.body.scrollHeight, behavior: 'smooth' })
    }, 650)
  }

  render() {
    return (
      <>
      <DemoNavbar />
        <main className="profile-page" ref="main">
          <section className="section">
            <Container>
              <Card className="card-profile shadow mt-9">
              <div className="lead font-weight-600 px-2 ml-5">Conocer más</div>
              <Row>
                <Col lg="12" align="center">                      
                  <motion.div name="business-case" className="px-4 my-5 shadow mx-5 p-4" 
                    style={{
                        width: "300px", borderWidth: 1, borderStyle: "solid", borderRadius: "10px",
                        borderColor: "#7795f8",
                        textAlign: "center"
                      }}
                    initial={{ opacity: 0, scale: 0.5 }}
                    animate={{ opacity: 1, scale: 1 }}
                    transition={{ duration: 0.8 }}>
                    Todo comienza entendiendo tu caso de negocio
                    <Row>
                      <Col lg="12">                         
                        <span style={{color: "Mediumslateblue"}}>
                            <i className="fa fa-group fa-3x"/>
                            </span>
                      </Col>
                    </Row>
                  </motion.div>
                </Col>
                <Col lg="12" align="center">                      
                  <motion.div name="plus-separator" className="px1 my-1 mx-1 py-2" 
                    initial={{ scale: 0.5 }}
                    animate={{
                      scale: [1, 1.2, 1.2, 1, 1],
                      rotate: [0, 0, 270, 270, 0],
                      borderRadius: ["20%", "20%", "50%", "50%", "20%"],
                    }}
                    // transition={{ duration: 0.2 }}
                    whileHover={{ scale: 1.1 }}
                    whileTap={{ scale: 0.9 }}
                    >
                    <span onClick={ () => {this.handleClickMore('1')} } style={{color: "Mediumslateblue"}}>
                      <i className="fa fa-plus-circle fa-3x"/>
                    </span>
                  </motion.div>
                </Col>
                <Col lg="12" align="center" className="d-none" data-entry="1">                      
                  <motion.div name="business-case" className="px-4 my-5 shadow mx-5 p-4" 
                    style={{
                        width: "300px", borderWidth: 1, borderStyle: "solid", borderRadius: "10px",
                        borderColor: "#7795f8",
                        textAlign: "center"
                      }}
                    initial={{ opacity: 0, scale: 0.5 }}
                    animate={{ opacity: 1, scale: 1 }}
                    transition={{ duration: 0.8 }}>
                      Lo siguiente es configurar tu agente digital, para que sea capaz de atender las solicitudes de tus clientes.
                      <Row>
                        <Col lg="12">                         
                          <span style={{color: "Mediumslateblue"}}>
                              <i className="fa fa-wrench fa-3x"/>
                              </span>
                        </Col>
                      </Row>
                    </motion.div>
                  </Col>
                  <Col lg="12" align="center" className="d-none" data-entry="1">                      
                    <motion.div name="plus-separator" className="px1 my-1 mx-1 py-2" 
                      initial={{ scale: 0.5 }}
                      animate={{
                        scale: [1, 1.2, 1.2, 1, 1],
                        rotate: [0, 0, 270, 270, 0],
                        borderRadius: ["20%", "20%", "50%", "50%", "20%"],
                      }}
                      // transition={{ duration: 0.2 }}
                      whileHover={{ scale: 1.1 }}
                      whileTap={{ scale: 0.9 }}
                      >
                        <span onClick={ () => {this.handleClickMore('2')} } style={{color: "Mediumslateblue"}}>
                          <i className="fa fa-plus-circle fa-3x"/>
                        </span>
                      </motion.div>
                  </Col>
                  <Col lg="12" align="center" className="d-none" data-entry="2">                      
                    <motion.div name="business-case" className="px-4 my-5 shadow mx-5 p-4" 
                      style={{
                          width: "300px", borderWidth: 1, borderStyle: "solid", borderRadius: "10px",
                          borderColor: "#7795f8",
                          textAlign: "center"
                        }}
                      initial={{ opacity: 0, scale: 0.5 }}
                      animate={{ opacity: 1, scale: 1 }}
                      transition={{ duration: 0.8 }}>
                      ¡Bien! Ahora hay que hacer pruebas controladas y asegurarnos que nuestro agente funciona como esperamos.
                      <Row>
                        <Col lg="12">                         
                          <span onClick={ () => {this.handleClickMore('3')} } style={{color: "Mediumslateblue"}}>
                              <i className="fa fa-flask fa-3x"/>
                              </span>
                        </Col>
                      </Row>
                    </motion.div>
                  </Col>
                  <Col lg="12" align="center" className="d-none" data-entry="2">                      
                    <motion.div name="plus-separator" className="px1 my-1 mx-1 py-2" 
                      initial={{ scale: 0.5 }}
                      animate={{
                        scale: [1, 1.2, 1.2, 1, 1],
                        rotate: [0, 0, 270, 270, 0],
                        borderRadius: ["20%", "20%", "50%", "50%", "20%"],
                      }}
                      // transition={{ duration: 0.2 }}
                      whileHover={{ scale: 1.1 }}
                      whileTap={{ scale: 0.9 }}
                      >
                        <span onClick={ () => {this.handleClickMore('3')} } style={{color: "Mediumslateblue"}}>
                          <i className="fa fa-plus-circle fa-3x"/>
                        </span>
                      </motion.div>
                  </Col>
                  <Col lg="12" align="center" className="d-none" data-entry="3">                      
                    <motion.div name="business-case" className="px-4 my-5 shadow mx-5 p-4" 
                      style={{
                          width: "300px", borderWidth: 1, borderStyle: "solid", borderRadius: "10px",
                          borderColor: "#7795f8",
                          textAlign: "center"
                        }}
                      initial={{ opacity: 0, scale: 0.5 }}
                      animate={{ opacity: 1, scale: 1 }}
                      transition={{ duration: 0.8 }}>
                      ¡Hola mundo! Tu agente digital puesto en línea y listo para atender las conversaciones con tus clientes.
                      <Row>
                        <Col lg="12">                         
                          <span style={{color: "Mediumslateblue"}}>
                              <i className="fa fa-comments-o fa-3x"/>
                              </span>
                        </Col>
                      </Row>
                    </motion.div>
                  </Col>
                  <Col lg="12" align="center" className="d-none" data-entry="3">                      
                    <motion.div name="plus-separator" className="px1 my-1 mx-1 py-2" 
                      initial={{ scale: 0.5 }}
                      animate={{
                        scale: [1, 1.2, 1.2, 1, 1],
                        rotate: [0, 0, 270, 270, 0],
                        borderRadius: ["20%", "20%", "50%", "50%", "20%"],
                      }}
                      // transition={{ duration: 0.2 }}
                      whileHover={{ scale: 1.1 }}
                      whileTap={{ scale: 0.9 }}
                      >
                        <span onClick={ () => {this.handleClickMore('4')} } style={{color: "Mediumslateblue"}}>
                          <i className="fa fa-plus-circle fa-3x"/>
                        </span>
                      </motion.div>
                  </Col>
                  <Col lg="12" align="center" className="d-none" data-entry="4">                      
                    <motion.div name="business-case" className="px-4 my-5 shadow mx-5 p-4" 
                      style={{
                          width: "300px", borderWidth: 1, borderStyle: "solid", borderRadius: "10px",
                          borderColor: "#7795f8",
                          textAlign: "center"
                        }}
                      initial={{ opacity: 0, scale: 0.5 }}
                      animate={{ opacity: 1, scale: 1 }}
                      transition={{ duration: 0.8 }}>
                      Medir y ajustar la efectividad de tu agente es importante, pues te aseguras que tus clientes reciban una mejor atención.
                      <Row>
                        <Col lg="12">                         
                          <span style={{color: "Mediumslateblue"}}>
                              <i className="fa fa-dashboard fa-3x"/>
                              </span>
                        </Col>
                      </Row>
                    </motion.div>
                  </Col>
                </Row>
              </Card>
            </Container>
          </section>
        </main>
        <SimpleFooter />
      </>
    );
  }
}

export default KnowMore;